<template></template>

<script>
import $ from 'jquery'
import { defineComponent } from 'vue'

import useScroll from '@/js/hooks/useScroll'
import { usePersistStore } from '@/js/store/persist'

export default defineComponent({
  mounted() {
    const { onScroll, getAnimation } = useScroll()
    const persist = usePersistStore()

    // Define benefitsSection variable
    const benefitsSection = document.querySelector('.benefits');

    const onFetchSearchParams = () => {
      const url = new URL(location)
      const clickId = url.searchParams.get('click_id')
      const utmParameters = {
        utm_source: url.searchParams.get('utm_source'),
        utm_medium: url.searchParams.get('utm_medium'),
        utm_campaign: url.searchParams.get('utm_campaign'),
        utm_content: url.searchParams.get('utm_content'),
        utm_term: url.searchParams.get('utm_term'),
      }

      return { clickId, utmParameters }
    }

    var headerItemsWrapper = $('#header-items-wrapper')
    var mobileMenuWrapper = $('#mobile-menu-wrapper')
    var mobileMenuButton = $('#header-mobile-menu')
    var mobileMenuItems = $('.nav-item')
    var headerSticky = $('.header-sticky')
    var fabButton = $('#back-top')

    const { clickId, utmParameters } = onFetchSearchParams()
    persist.onUpdateFromSearchParams(clickId, utmParameters)

    // header
    let previous = $(window).scrollTop()
    let headerHeight = headerSticky.height()

    $(window).on('scroll', () => {
      const current = $(window).scrollTop()
      const action = onScroll(current, headerHeight)

      if (previous > current && !headerItemsWrapper.hasClass('menu-opened')) {
        headerItemsWrapper.delay(500).css({ opacity: 0.7 })
      } else {
        headerItemsWrapper.delay(500).css({ opacity: 1 })
      }

      if (action === 'fade-out') {
        headerSticky.removeClass('sticky-bar')
        fabButton.fadeOut(500)
      } else {
        headerSticky.addClass('sticky-bar')
        fabButton.fadeIn(500)
      }

      previous = current
      setTimeout(() => {
        headerItemsWrapper.delay(500).css({ opacity: 1 })
      }, [5000])
    })

    headerSticky.toggleClass('sticky-bar', $(this).scrollTop() > headerHeight)

    // pre-loader
    $(window).on('load', () => {
      $('#preloader-active').delay(450).fadeOut('slow')
      $('body').delay(450).css({ overflow: 'visible' })
    })

    // back-to-top
    $('#back-top a').on('click', function () {
      $('body, html').animate({ scrollTop: 0 }, 300)
    })

    $(window).on('scroll resize', function () {
      var windowTop = $(window).scrollTop()
      var windowBottom = windowTop + $(window).height()

      $('.fade-up, .fade-down, .fade-left, .fade-right').each(function () {
        var element = $(this)
        var animation = getAnimation(windowTop, windowBottom, element)
        element.addClass(animation + '-active')
      })
    })

    $(window).trigger('scroll')

    const onCloseMenu = () => {
      mobileMenuWrapper.addClass('slide-out')
      mobileMenuWrapper.removeClass('menu-opened')

      setTimeout(() => {
        $('.mobile-screen-overlay').hide()
        headerItemsWrapper.removeClass('menu-opened')
      }, [300])
    }

    $(window).on('resize', function () {
      if ($(window).width() >= 991) {
        onCloseMenu()
      }
    })

    $('a[href="#how-it-works"]').on('click', function () {
      $('body, html').animate({ scrollTop: $('#how-it-works').offset().top }, 100)
    })

    $('a[href="#about-us"]').on('click', function () {
      $('body, html').animate({ scrollTop: $('#about-us').offset().top }, 100)
    })

    $('.mobile-screen-overlay').on('click', function () {
      if (mobileMenuWrapper.hasClass('menu-opened')) {
        onCloseMenu()
      }
    })

    mobileMenuButton.on('click', function () {
      if (!mobileMenuWrapper.hasClass('menu-opened')) {
        mobileMenuWrapper.removeClass('slide-out')
        mobileMenuWrapper.toggleClass('menu-opened')
        headerItemsWrapper.toggleClass('menu-opened')
        $('.mobile-screen-overlay').show()
        return
      }

      onCloseMenu()
    })

    mobileMenuItems.on('click', function () {
      headerItemsWrapper.removeClass('menu-opened')
      mobileMenuWrapper.removeClass('menu-opened')
      // mobileMenuWrapper.toggleClass('slide-out')
      $('.mobile-screen-overlay').hide()
    })

    $('#mobile-menu').on('click', function (event) {
      event.stopPropagation()
    })

    // Load and manipulate SVG
    fetch('/dist/img/background/blob-lg.svg')
      .then(response => response.text())
      .then(svgData => {
        const parser = new DOMParser()
        const svgDoc = parser.parseFromString(svgData, 'image/svg+xml')
        const svgElement = svgDoc.querySelector('svg')
        const benefitBgColor = getComputedStyle(document.documentElement).getPropertyValue('--benefit-bg-color')
        svgElement.querySelectorAll('*').forEach(element => {
          if (element.getAttribute('fill')) {
            element.setAttribute('fill', benefitBgColor)
          }
        })
        
        if (benefitsSection) {
          const newSvgData = new XMLSerializer().serializeToString(svgDoc);
          benefitsSection.style.backgroundImage = `url("data:image/svg+xml,${encodeURIComponent(newSvgData)}")`
        }
      })
      .catch(error => console.error('Error loading SVG:', error))
  },
})
</script>
